<template>
    <div>
        <div v-if="isLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <h6 class="text-body mt-3">Loading</h6>

        </div>
        <div v-if="!isLoading && errorLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-icon-exclamation-triangle class="h3" variant="danger"></b-icon-exclamation-triangle>
            <h6 class="text-danger mt-3">Ooops, there has been an error</h6>

        </div>
        <div v-if="!isLoading && !errorLoading">
            <div class="text-left" >

                <h5 class="border-bottom pb-2 text-primary">{{ application.id !== undefined ? "Edit" : "Create"}} application</h5>

                <b-form-group label-size="sm" class="mb-4"
                              label="Job reference:">
                    <b-form-input
                            size="sm"
                            v-model="application.job_ref"
                            type="text"
                            placeholder="Enter job reference"
                    ></b-form-input>
                    <error-display v-model="errors" ident="job_ref"></error-display>
                </b-form-group>

                <b-row>
                    <b-col>
                        <b-form-group label-size="sm" label="First name:">
                            <b-form-input
                                    size="sm"
                                    v-model="application.first_name"
                                    type="text"
                                    placeholder="Candidate first name"
                            ></b-form-input>
                            <error-display v-model="errors" ident="first_name"></error-display>
                        </b-form-group>
                    </b-col>

                    <b-col>
                        <b-form-group
                                label-size="sm"
                                label="Middle names:">
                            <b-form-input
                                    size="sm"
                                    v-model="application.middle_names"
                                    type="text"
                                    placeholder="Candidate middle names"
                            ></b-form-input>
                            <error-display v-model="errors" ident="middle_names"></error-display>
                        </b-form-group>
                    </b-col>

                    <b-col>
                        <b-form-group
                                label-size="sm"
                                label="Surname:">
                            <b-form-input
                                    size="sm"
                                    v-model="application.surname"
                                    type="text"
                                    placeholder="Candidate surname"
                            ></b-form-input>
                            <error-display v-model="errors" ident="surname"></error-display>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <b-col>
                        <b-form-group
                                label-size="sm"
                                label="Date of birth:">
                            <masked-input  class="form-control form-control-sm"
                                          v-model="application.dob"
                                          mask="11/11/1111"
                                          placeholder="dd/mm/yyyy"
                            />
                            <error-display v-model="errors" ident="dob"></error-display>
                        </b-form-group>
                    </b-col>

                    <b-col v-if="application.id == null || application.status === 'in_progress' ">
                        <b-form-group
                                label-size="sm"
                                label="Nationality:">
                            <b-select
                                    size="sm"
                                    v-model="application.nationality_id"
                            >
                                <optgroup v-for="(group,key) in nationalities" v-bind:label="group.name"
                                          v-bind:key="key">
                                    <option v-for="(nationality,nationalityKey) in group.nationalities"
                                            v-bind:key="nationalityKey" :value="nationality.id">
                                        {{ nationality.name }}
                                    </option>
                                </optgroup>
                            </b-select>

                            <error-display v-model="errors" ident="nationality_id"></error-display>
                        </b-form-group>
                    </b-col>

                </b-row>

                <b-form-group v-if="application.id == null && (this.$store.getters.isSupervisor || this.$store.getters.isAdmin)"
                              label-size="sm"
                              label="Assign to these users:">
                    <div v-if="isLoadingUsers" class="d-flex align-items-center border p-2 rounded">
                        <b-spinner small variant="primary" class="mr-2"></b-spinner>
                        Loading users
                    </div>
                    <div v-else>
                        <v-select
                                ref="userSelect"
                                label="name"
                                v-model="application.user_ids"
                                :multiple="true"
                                :reduce="option => option.value"
                                :options="users"
                                :filterable="true"
                                placeholder="Search for user">
                        </v-select>
                        <div class="mt-2 text-right">
                            <b-button :pill="true" variant="secondary" size="sm" @click="deselectUser">Deselect
                                all
                            </b-button>
                            <b-button :pill="true" variant="primary" class="ml-1" size="sm"
                                      @click="selectAllUsers">Select all
                            </b-button>
                        </div>
                    </div>

                    <error-display v-model="errors" ident="supervisor_ids"></error-display>
                </b-form-group>



                <div class="mt-3" v-if="application.id != null">
                    <div v-for="tag in tags" v-bind:key="tag.id">

                        <b-form-group
                                label-size="sm"
                                :label="tag.name+':'">
                            <b-form-input size="sm" v-if="tag.input_type === 'free_entry'"
                                          v-model="tag.tag_value"
                                          type="text"
                                          :placeholder="tag.name"
                            ></b-form-input>

                            <vue-simple-suggest size="sm" v-if="tag.input_type === 'autocomplete'"
                                                v-model="tag.tag_value"
                                                :list="tag.tags"
                                                :styles="autoCompleteStyle"
                                                value-attribute="id"
                                                :nullable-select="true"
                                                :filter-by-query="true">
                            </vue-simple-suggest>

                            <b-select size="sm" v-if="tag.input_type === 'list'"
                                      value-field="id"
                                      text-field="tag_value"
                                      :options="tag.tags"
                                      v-model="tag.tag_value">
                            </b-select>

                            <error-display v-model="errors" :ident="'tags.'+tag.id"></error-display>
                        </b-form-group>
                    </div>
                </div>

                <input type="hidden" v-if="application.id != null"
                       v-model="application.id"/>
                <div class="text-right">
                <b-button @click="saveForm" variant="success">{{ application.id == null ? 'Create application' : 'Save' }}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src

    import VueSimpleSuggest from 'vue-simple-suggest'

    import 'vue-simple-suggest/dist/styles.css'
    import MaskedInput from "vue-masked-input/src/MaskedInput";
    import {getResource, saveResource} from "../../modules/api/methods";
    import {appConfig, applicationDetail, applicationSave, baseApi, usersDropdown} from "../../modules/api/endpoints";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import {containsErrors} from "../../modules/helpers/helpers";
    import vSelect from "vue-select"
    import moment from "moment"; // Optional CSS

    export default {
        name: 'basic_info',
        data: function () {
            return {
                isLoadingUsers: false,
                users : [],
                errorLoading : false,
                isLoading: false,
                application: {},
                nationalities: [],
                tags: [],
                errors: {},
                autoCompleteStyle: {
                    vueSimpleSuggest: "position-relative",
                    inputWrapper: "",
                    defaultInput: "form-control",
                    suggestions: "position-absolute list-group z-1000",
                    suggestItem: "list-group-item"
                },
                apiEndpoint: baseApi() + 'users/dropdown?roles=user'
            }
        },
        props: {
            applicationId: null
        },
        components: {
            "v-select": vSelect,
            ErrorDisplay,
            MaskedInput,
            'vue-simple-suggest': VueSimpleSuggest,
        },
        created() {
            this.loadForm();
            this.application = this.$store.state.application;
            this.loadAllUsers();
        },
        methods: {
            selectAllUsers() {
                this.application.user_ids = _.map(this.users, 'value');
                const select = this.$refs.userSelect;
                select.options.forEach(option => {
                    select.select(option);
                });
                select.open = false
            },
            deselectUser() {
                this.application.user_ids = [];
                const select = this.$refs.userSelect;
                select.options.forEach(option => {
                    select.deselect(option);
                });
                select.open = false
            },
            loadAllUsers() {
                this.isLoadingUsers = true;
                getResource(usersDropdown).then((resp) => {
                    this.users = resp.data.success;
                }).catch((ex) => {
                    this.$root.$children[0].handleApiError(ex, this.loadAllUsers);
                }).finally(() => {
                    this.isLoadingUsers = false;
                });
            },
            saveForm() {
                this.errors = {};
                this.isLoading = true
                var postData = this.application;
                postData.from_portal = true;
                postData.tags = {};
                _.each(this.tags, function (tag) {
                    postData.tags[tag.id] = tag.tag_value;
                });
                saveResource(applicationSave, postData).then(response => {
                    if(this.$router.currentRoute.name == 'applicationDetailEdit') {
                        this.$router.push({'name': 'applicationDetail', params: {applicationId : this.applicationId}}).catch(error => {});
                    } else {
                        this.$router.push({'name': 'applications', params: {data_updated: true}}).catch(error => {});
                    }
                }).catch(error => {
                    if (containsErrors(error)) {
                        this.errors = error.data.errors;
                    } else {
                        this.$root.$children[0].handleApiError(error,this.saveForm)
                    }
                }).finally(() => {
                    this.isLoading = false
                });
            },
            processTags(tags) {
                var app = this.application;
                var tagFinal = [];
                _.each(tags, function (tag) {
                    var row = {};
                    row.id = tag.id;
                    row.name = tag.name;
                    row.input_type = tag.input_type;
                    var selectedVal = app.tags !== undefined ? app.tags.filter(row => row.tag_type_id === tag.id) : [];
                    if (selectedVal.length > 0) {
                        row.tag_value = selectedVal[0].tag_value;
                    } else {
                        row.tag_value = null;
                    }
                    if (tag.tags != null) {
                        var options = [];
                        _.each(tag.tags, function (option) {
                            options.push(option.tag_value);
                        });
                        row.tags = options;
                    } else {
                        row.tags = [];
                    }
                    tagFinal.push(row);
                });

                this.tags = tagFinal;
            },
            loadForm() {
                this.isLoading = true
                getResource(appConfig).then(response => {
                    this.nationalities = response.data.success.nationalities;
                    var tags = response.data.success.tags;

                    if (this.applicationId != null) {

                        getResource(applicationDetail(this.applicationId)).then((res) => {
                            //PROCESS TAGS
                            this.application = res.data.success.data;
                            if (this.application.users !== undefined && this.application.users.length > 0) {
                                var userIds = [];
                                _.each(this.application.users, function (obj) {
                                    userIds.push(obj.id)
                                });
                                this.application.user_ids = userIds;
                            }
                            this.application.dob = moment(this.application.dob).format("DD/MM/YYYY");
                            this.processTags(tags);
                            this.isLoading = false;
                            this.errorLoading = false;
                        }).catch((error) => {
                            this.$root.$children[0].handleApiError(error,this.loadForm);
                            this.isLoading = false;
                            this.errorLoading = true;
                        });
                    } else {
                        this.application = {};
                        this.processTags(tags);
                        this.isLoading = false;
                        this.errorLoading = false;
                    }
                }).catch(error => {
                    this.$root.$children[0].handleApiError(error,this.loadForm);
                    this.isLoading = false;
                    this.errorLoading = true;
                })
            }
        },
    }
</script>

